.container-facebook {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
}

.header-facebook {
  position: absolute;
  top: 0;
  width: 100%;
  height: 6rem;
  background-color: #3b5998;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-logo {
  width: 9rem;
  height: 9rem;
}

.loading {
  width: 5rem;
  height: 5rem;
  border: 0.5rem solid #3b5998;
  border-radius: 50%;
  border-top: 0.5rem solid #fafafa;
  animation: spin 1s linear infinite;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}